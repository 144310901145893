body {
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

section {
  height: 100%;
  width: 100%;
  border: none;
  overflow: hidden;
  position: relative;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
}

.Resizer.horizontal:hover, .Resizer.horizontal.resizing {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover, .Resizer.vertical.resizing {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.DragLayer {
z-index: 1;
pointer-events: none;
}

.DragLayer.resizing {
pointer-events: auto;
}

.DragLayer.horizontal {
cursor: row-resize;
}

.DragLayer.vertical {
cursor: col-resize;
}

.controls {
  position: absolute;
  left: 1rem;
  top: 1rem;
  overflow-y: scroll;
  background: #ffffff55;
  height: fit-content;
  padding: 0.5rem 0.5rem;
}

#root > main > section > div > div.Pane.vertical.Pane2 > div > div.Pane.horizontal.Pane2 {
  overflow-y: scroll!important;
}